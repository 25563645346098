import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Cards from "../components/cards";
import Layout from "../components/layout";

const useStyles = makeStyles((theme) => ({
  box1: {
    display: "grid",
    gridGap: "50px 100px",
    alignItems: "center",
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      justifyItems: "center",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  image: {
    maxHeight: 320,
    maxWidth: "100%",
    objectFit: "contain",
  },
  box2: {
    maxWidth: 480,
  },
  box3: {
    display: "grid",
    gridGap: "30px",
    marginTop: 90,
    gridTemplateColumns: "repeat(auto-fit, minmax(min-content, 340px))",
  },
}));

export default function Customers({ data }) {
  const classes = useStyles();
  const pageData = data.markdownRemark.frontmatter.data;
  let email = "";
  if (typeof window !== "undefined") {
    email = window.localStorage.getItem("email");
    window.posthog.identify(email);
    window.posthog.people.set({ email });
  }

  return (
    <Layout>
      <div style={{ background: "#EAEFFD", padding: "60px 0" }}>
        <Container>
          <div className={classes.box1}>
            <img src="/media/message.svg" alt="" className={classes.image} />
            <div className={classes.box2}>
              <p
                style={{
                  fontSize: 44,
                  fontWeight: 600,
                  color: "#546fff",
                  marginTop: 0,
                }}
              >
                Thank you!
              </p>
              <p>
                Your message has been sent and a member of our team will be in
                touch with you shortly
                {email && (
                  <span>
                    {" "}
                    at <a href={`mailto:${email}`}>{email}</a>
                  </span>
                )}
                .
              </p>
              <p>
                If you do not receive a confirmation email from us within the
                next few minutes please check your spam or junk mail folder.
              </p>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div style={{ marginTop: 100 }} />

        <p style={{ fontSize: 32 }}>
          Meanwhile, here are some{" "}
          <a
            href="https://nanonets.com/#case-studies"
            target="_blank"
            rel="noopener noreferrer"
          >
            case studies
          </a>{" "}
          that you may like
        </p>

        <div style={{ marginTop: 40 }} />

        <Cards cards={pageData.cards} />

        <div style={{ marginTop: 200 }} />
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "thank-you" } }) {
      frontmatter {
        data {
          cards {
            title
            summary
            poster
            href
          }
        }
      }
    }
  }
`;
